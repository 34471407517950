import React from "react"
import PageLogger from "../../PageLogger/PageLogger";
import {Button} from "react-bootstrap";

const CoverPage = () => {
    return (
        <div className="full-page bg-dark d-flex align-items-center">
            <PageLogger page="cover" />
            <div className={"container text-center col-xxl-8 px-4 py-5 bg-dark"}>
                <h1 className="display-4 fw-bold text-white">The 21st Problem</h1>
                <div className="col-lg-6 mx-auto">
                    <p className="lead text-white">Ensuring Security and Prosperity in a Rapidly Changing World</p>
                    <div className="d-grid gap-2 d-sm-flex justify-content-sm-center mb-5">
                        <Button variant="secondary" href="about">About</Button>
                        <Button variant="light" href="problem">The Problem</Button>
                        <Button variant="light" href="solution">The Solution</Button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CoverPage

